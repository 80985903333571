import React from "react";
import PortableText from "./portableText";

const TextImage = ({ content, index, noCol }) => {
  return (
    <div className={`h-100 row focusElement`}>
      <div
        className={`lead text-left mt-0 mb-4 ${
          !noCol && "my-lg-auto col-md-12 col-lg-6 col-10 col-sm-12"
        } ${!noCol && index % 2 !== 1 ? "order-lg-2" : ""}`}
      >
        <PortableText blocks={content?.text} />
      </div>
      <div
        className={`mx-auto mt-auto col-10 ${!noCol && "col-lg-6"} ${
          noCol && "col-lg-12 px-0"
        } text-center ${!noCol && index % 2 !== 1 ? "order-lg-1" : ""}`}
      >
        {content?.images && (
          <>
            <img
              src={content?.images?.[0]?.image.asset.url}
              alt="A non-intrusive on-site survey solution"
              className="svg-shadow w-100 mb-2"
            />
            {content?.imageText && (
              <span className="image_figure">
                <PortableText blocks={content?.imageText} />
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TextImage;
