import React from "react";

const Video = ({ content }) => {
  if (content?.video?.asset?.url) {
    return (
      <div
        className="container bg-white svg-shadow pt-3 px-4 pb-2 border border-light"
        style={{ borderRadius: "20px" }}
      >
        <video
          className="w-100"
          controls
          width="auto"
          poster={content?.image?.asset?.url ?? ""}
          style={{ objectFit: "cover" }}
        >
          <source src={content?.video?.asset?.url} />
          <track
            src={content?.captions?.asset?.url}
            kind="captions"
            label="Captions"
          />
        </video>
      </div>
    );
  }
  return null;
};

export default Video;
