import React from "react";

const CheckList = ({ content, noCol }) => {
  return (
    <ul className="row homepage_usps list-unstyled pl-5 pb-4">
      {content?.list?.map((li, idx) => {
        return (
          <li
            key={`${content?._key}-${idx}`}
            className={`lead mt-4 pl-1 ${!noCol && "col-lg-6"} ${
              noCol && "w-100"
            }`}
          >
            {li}
          </li>
        );
      })}
    </ul>
  );
};

export default CheckList;
