import React from "react";
import BaseBlockContent from "@sanity/block-content-to-react";
import Button from "./button";

const PortableText = ({ blocks }) => {
  return (
    <BaseBlockContent
      blocks={blocks}
      serializers={{
        marks: {
          link: ({ mark, children }) => {
            return <a href={mark.url}>{children}</a>;
          },
          strong: ({ children }) => {
            return <strong className="font-weight-bold">{children}</strong>;
          },
        },
        types: {
          link: (props) => (
            <a
              href={props.node.url}
              noFollow={props.node.nofollow}
              title={props.node.text}
            >
              {props.node.text}
            </a>
          ),
          button: (props) => (
            <div className="mx-auto my-4">
              <Button href={props.node.url} title={props.node.title} />
            </div>
          ),
          block(props) {
            switch (props.node.style) {
              default:
                return <p>{props.children}</p>;
            }
          },
        },
      }}
    />
  );
};

export default PortableText;
