import React from "react";
import TextImage from "./textImage";
import Video from "./video";
import CheckList from "./checkList";
import Button from "./button";

const BlockContent = ({ content }) => {
  return content?.map((block, idx) => {
    switch (block._type) {
      case "heading":
        return (
          <div className="container integration mb-3" key={block._key}>
            <div className="row">
              <h2 className="h4 pb-2 border-bottom col-lg-6">
                {block?.heading ?? ""}
              </h2>
            </div>
          </div>
        );
      case "textImage":
        return (
          <section className={idx % 2 === 1 ? "grey" : ""} key={block._key}>
            <div className="container">
              <TextImage content={block} index={idx} />
            </div>
          </section>
        );
      case "checkList":
        return (
          <div className="container" key={block._key}>
            <CheckList content={block} />
          </div>
        );
      case "video":
        return (
          <section className="container mt-5" key={block._key}>
            <div className="row">
              <div className="col-12 col-lg-8 mx-auto">
                <Video content={block} />
              </div>
            </div>
          </section>
        );
      case "button":
        return (
          <section
            className="container mt-5 d-flex justify-content-center"
            key={block._key}
          >
            <Button href={block.url} title={block.title} />
          </section>
        );
      case "twoColumns":
        return (
          <div className={idx % 2 === 1 ? "grey" : ""} key={block._key}>
            <div className="container">
              <div className="row">
                {block?.columns?.map((col) => {
                  switch (col._type) {
                    case "textImage":
                      return (
                        <section className="col-lg-6" key={col._key}>
                          <div className="container h-100">
                            <TextImage content={col} noCol />
                          </div>
                        </section>
                      );
                    case "checkList":
                      return (
                        <section
                          className="col-lg-6 h-auto my-auto"
                          key={col._key}
                        >
                          <div className="container">
                            <CheckList content={col} noCol />
                          </div>
                        </section>
                      );
                    case "video":
                      return (
                        <section
                          className="col-lg-6 focusElement my-auto"
                          key={col._key}
                        >
                          <div className="container">
                            <Video content={col} />
                          </div>
                        </section>
                      );
                    default:
                      return null;
                  }
                })}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  });
};

export default BlockContent;
