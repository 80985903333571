import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BlockContent from "../components/blockContent";

export const query = graphql`
  query IndexQuery {
    page: sanityIndexPage {
      _id
      _updatedAt
      _rawContent(resolveReferences: { maxDepth: 10 })
      cta
      ctaButton {
        title
        url
      }
      image {
        image {
          asset {
            url
          }
        }
        alt
      }
      testimonials {
        _key
        logo {
          asset {
            url
          }
        }
        quote
        title
      }
      metaInfo {
        description
        title
        someTitle
        imageAlt
        _key
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const IndexPage = ({ data, errors }) => {
  /*if (errors) {
    return (
      <GraphQLErrorList errors={errors} />
    );
  }*/

  const page = data?.page;

  if (!page) {
    throw new Error(
      'Missing "Home page settings". Open the studio at http://localhost:3333 and add some content to "Home page" and restart the development server.'
    );
  }

  return (
    <Layout theme="light" metaInfo={page?.metaInfo}>
      <div className="content_header homepage introduction">
        <div className="container">
          <div className="row text-left">
            <div className="col-12 col-md-10 col-lg-5 text-left d-flex align-content-center flex-wrap mx-auto order-2 order-md-2 order-lg-1 order-sm-2 order-xs-2">
              <h1
                className="text-left"
                style={{ marginTop: "0", paddingTop: "0px" }}
              >
                {page?.cta ??
                  "See why users visit your site – why some succeed and others fail"}
              </h1>
			  <h1
                className="text-left"
                style={{ marginTop: "0", paddingTop: "0px" }}
              >
                {"Start collecting user input on your website today with a free trial of Task Analytics"}
              </h1>
              <br />
              <p className="text-center">
                <a
                  className="btn btn-outline-primary text-center internal_link"
                  href={"https://admin2.taskanalytics.com"}
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Get started with Task Analytics"
                  style={{
                    height: "100%",
                    width: "100%",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                  }}
                >
                  {"Start Free Trial"}
                </a>
              </p>
            </div>
            <div className="col-10 mx-auto col-lg-7 text-center order-1 order-md-1 order-lg-2 order-sm-1 order-xs-1">
              <img
                src={
                  page?.image?.image?.asset?.url ??
                  "/assets/img/PuzzleLightBorder.png"
                }
                style={{ width: "100%", padding: "40px 0" }}
                className="svg-shadow"
                alt={
                  page?.image?.alt ??
                  "Task Analytics - Measuring your digital customer experience"
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials */}
      <div className="container pt-2 mt-5">
        <div className="row justify-content-center">
          {page?.testimonials?.map((testimonial) => {
            return (
              <div
                key={testimonial?._key}
                className="mb-4 mb-lg-2 mt-lg-2 col-sm-10 col-md-6 col-lg-4 d-flex flex-column align-items-center"
              >
                {testimonial?.logo && (
                  <img
                    height="50"
                    width="auto"
                    src={testimonial?.logo?.asset?.url}
                    alt={`${testimonial?.title ?? ""} logo`}
                  />
                )}
                <blockquote className="text-center text-dark mt-3">
                  <strong style={{ fontWeight: "900" }}>“</strong>
                  {testimonial?.quote}
                </blockquote>
              </div>
            );
          })}
        </div>
      </div>

      {/* CMS "Content"-FIELD */}
      {page?._rawContent && <BlockContent content={page?._rawContent} />}
    </Layout>
  );
};

export default IndexPage;
