import React from "react";

const Button = ({ title, href, target }) => {
  return (
    <a
      className="btn btn-outline-primary text-center internal_link"
      href={href ?? "/"}
      rel="noopener noreferrer"
      target={target ?? "_blank"}
      title={title ?? ""}
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "10px",
      }}
    >
      {title ?? ""}
    </a>
  );
};

export default Button;
